.slick-prev,
.slick-next {
  z-index: 9;
}
.slick-prev {
  left: -110px !important;
}
.slick-next {
  right: -100px !important;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-family: monospace !important;
  font-size: 70px !important;
}
.slick-next:before {
  content: ">" !important;
}
.slick-prev:before {
  content: "<" !important;
}
.slick-dots {
  position: unset !important;
}
.table > :not(caption) > * > * {
  padding: 1rem 0.5rem !important;
}
input::placeholder {
  background-color: transparent;
}

.loader2 {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid rgb(165, 163, 163);
  border-right-color: #000;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
.loader1 {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid rgb(165, 163, 163);
  border-right-color: white;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
.all-billionaire-data p {
  margin-bottom: 5px;
}
@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}
@media (max-width: 1024px) {
  .slick-next {
    right: -50px !important;
  }
  .slick-prev {
    left: -60px !important;
  }
  .listing-toggle-open {
    display: block !important;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    background-color: black;
    z-index: 99;
    text-align: center;
    position: absolute;
    top: 80px;
  }
  .listing-toggle-closed {
    display: none;
  }
}
@media (max-width: 768px) {
  .lead {
    font-size: 1.15rem !important;
  }
  .slick-prev {
    left: 20px !important;
  }
  .slick-next {
    right: 20px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 0 !important;
  }
  .slick-dots li {
    margin: 0 2px !important;
  }
  .slick-dots li button {
    padding: 2px !important;
  }
  .slick-dots {
    position: absolute !important;
    bottom: 7px !important;
  }
  .pagination li {
    margin: 0 5px;
    height: 38px;
    width: 38px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .headline-description img {
    height: 100% !important;
    width: 100% !important;
  }
  .headline-description p {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 425px) {
  .headline-description img {
    height: auto !important;
    width: auto !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
  margin-bottom: 8px;
  padding: 15px 0 !important;
  list-style: none;
}

.pagination li {
  margin: 0 5px;
  height: 38px;
  width: 38px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.pagination li a {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination li.active {
  background-color: #000;
  color: white;
}
.pagination li.active a {
  color: white;
}
.pagination li.disabled a {
  color: #ccc;
  pointer-events: none;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}
.abril-fatface-regular {
  font-family: "Abril Fatface", serif;
  font-weight: 900;
  font-style: normal;
}
@media only screen and (min-width: 1720px) {
  .sideAd {
    display: flex !important;
  }
}
.desktopSlider {
  display: none !important;
}
.tabletSlider {
  display: none !important;
}
@media only screen and (min-width: 768px) {
  .tabletSlider {
    display: flex !important;
  }
  .mobileSlider {
    display: none !important;
  }
}
@media only screen and (min-width: 1440px) {
  .desktopSlider {
    display: flex !important;
  }
  .tabletSlider {
    display: none !important;
  }
}

/* Global custom styles */
.blog-content img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blog-content p {
  font-size: clamp(0.5rem, 4vw, 1rem);
}

/* Global Styles for UL and LI */
.blog-content ul li {
  font-size: clamp(0.5rem, 4vw, 1rem); /* Use responsive font size */
  list-style-type: disc; /* Bullet points for list */
  /* list-style-position: inside; */
  margin-left: 30px;
  padding-left: 20px; /* Indentation for list items */
}

.blog-content ul {
  margin-bottom: 1rem; /* Add some space after the unordered list */
}

.blog-content li {
  margin-bottom: 0.5rem; /* Space between list items */
}

.blog-content h1 {
  font-size: clamp(1rem, 6vw, 2rem);
}

.blog-content h2 {
  font-size: clamp(1rem, 3.5vw, 1.5rem);
}

.blog-content h3 {
  font-size: clamp(1rem, 3.5vw, 1.5rem);
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog-content h4 {
  font-size: clamp(1rem, 3.5vw, 1.2rem);
}

.blog-content a {
  color: blue;
}

.blog-content {
  position: relative;
  padding: 20px;
} 